/* eslint-disable camelcase */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import Webcam from 'react-webcam';

import { Grid, Typography } from '@mui/material';

import * as faceDetection from '@tensorflow-models/face-detection';
import { FaceDetector } from '@tensorflow-models/face-detection';
import { MediaPipeFaceDetectorTfjsModelConfig } from '@tensorflow-models/face-detection/dist/tfjs/types';

import '@mediapipe/face_detection';

import { isMobile } from 'wink-lib';

import {
  setLivenessError,
  setLivenessTipsAccepted,
  setIsFraud,
  setProcessingMediaData,
  setIsLoading as setAppIsLoading,
  setDeviceFlag,
  setDeniedPermissions,
  setShouldShowTipsImage,
  setEnrollErrorCounter,
  setIsFirstInstruction,
  setWinkToken,
  setSessionToken,
} from 'app/slices/appSlice';
import {
  setIsSubDrawerOpen,
  setMediaRecording,
  setShowMediaDeviceIcons,
} from 'app/slices/drawerSlice';
import { setVoice } from 'app/slices/userSlice';

import {
  useInvitationId,
  useIsBackendWasm,
  useLocalStorage,
} from 'context/AppContext';
import { MediaContext } from 'context/MediaContext';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { useRequestId } from 'hooks/useRequestId';
import { useWStatus } from 'hooks/useWebsocketStatus';

import { verifyDevice } from 'api/device';
import { getRecognitionMessage } from 'api/recognition-message';
import { initSynthesizer, restartSynthesizer, speak } from 'api/speech';
import { getProfileCompletion } from 'api/user';

import { StyledButton } from 'components/button';
import {
  calculateDistance,
  isFaceCloseToBorder,
  UploadOptions,
  uploadImageToAzure,
} from 'components/liveness/livenessDetectionsService';
import { Loader } from 'components/loader/Loader';
import { getSupportedAudioMimeTypes } from 'components/utils';

import { instructions } from 'const/instructions';
import { getToken, getGrantedPermissions } from 'const/localStorage';
import {
  YellowCode,
  YellowCodeToken,
  ErrorCode,
  EnrollMaxErrors,
  ExistingAccountCode,
  FraudCode,
  LivenessErrorCode,
  oAuthRequestIdCode,
  SuccessCode,
  TokenCode,
  OnWorking,
  IncompleteEnrollment,
  VoiceLivenessError,
  NewAccount,
  WsErrorMessageCode,
} from 'const/wsTypeMessages';

import configData from 'config/config.json';

import 'css/imageProgress.css';

import { ImageProgress } from './ImageProgress';
import { LivenessTipImage } from './tips/styles';

let ws: WebSocket | null = null;
let audioRecorder: MediaRecorder | null = null;
let isStreaming: boolean | null = null;
let faceNoErrors: boolean = true;

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 10,
    overflow: 'hidden',
  } as Record<string, React.CSSProperties>,
  loader: {
    width: 60,
    height: 60,
    bottom: '15%',
    left: '42%',
    color: '#3D4897',
    zIndex: 100,
  } as React.CSSProperties,
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '100%',
    position: 'absolute',
    transform: 'scaleX(-1)',
  } as React.CSSProperties,
  video: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '100%',
  } as React.CSSProperties,
  canvas: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: 9,
    width: '100%',
    height: '100%',
  } as React.CSSProperties,
  greenButton: {
    backgroundColor: '#1AA7B0',
    color: '#FFF',
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#636e72',
      color: '#FFF',
    },
  } as React.CSSProperties,
  livenessTipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: '10px 30px 30px',
  } as React.CSSProperties,
};

interface Props {
  setSymbioCode: (code: string | null) => void;
  symbioCode: string | null;
  onSuccess: (code?: string) => void;
  setShowInstructions: Dispatch<SetStateAction<boolean>>;
  showInstructions: boolean;
  setIsYellowCode: Dispatch<SetStateAction<boolean>>;
  isYellowCode: boolean;
  setYellowRetryPressed: Dispatch<SetStateAction<boolean>>;
  yellowRetryPressed: boolean;
  setWsErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

interface LivenessPictureData {
  blob: Blob | null;
  fileName: string | null;
}

interface FaceIDData {
  id: number | null;
  confidence: number;
}
interface ParsedMessage {
  uuid: string | null;
  faceId: number | string | null;
}

interface SymbioResponse {
  recognition_event_uuid: string;
  recognition_type: string;
  data_status: string;
  data_quality: any;
  result_data: Array<{ [key: string]: { id: number; confidence: number } }>;
  status: string;
  liveness: {
    status: string;
    quality: number;
    real_score: number;
    result: string;
  };
}

const uploadOptions: UploadOptions = {
  sasToken: `${configData.AZURE_SAS_TOKEN}`,
  containerName: `${configData.AZURE_STORAGE_CONTAINER_NAME}`,
  account: `${configData.AZURE_STORAGE_ACCOUNT}`,
};

export const LivenessDetection: React.FC<Props> = ({
  setSymbioCode,
  symbioCode,
  onSuccess,
  setShowInstructions,
  setIsYellowCode,
  isYellowCode,
  showInstructions,
  setYellowRetryPressed,
  yellowRetryPressed,
  setWsErrorMessage,
}) => {
  const [isLivenessSuccessful, setIsLivenessSuccessful] = useState(false);
  const [livenessTipsActive, setLivenessTipsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [textToRead, setTextToRead] = useState(null);
  const [hasCaptured, setHasCaptured] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [intervalsPaused, setIntervalsPaused] = useState<boolean | null>(null);

  // Params
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id') || '';

  // LocalStorage
  const localStorage = useLocalStorage();
  const isBackendWasm = useIsBackendWasm();

  // invitationIs queryParam for guestUser
  const invitationId = useInvitationId();

  // Face Detection States
  const [blazefaceLoaded, setBlazefaceLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageProgressText, setImageProgressText] = useState('');
  const [imageProgressErrorText, setImageProgressErrorText] = useState('');
  const [userImageSuccess, setUserImageSuccess] = useState(false);
  const [userImageError, setUserImageError] = useState(false);
  const [userPositionError, setUserPositionError] = useState(false);

  // Timer
  const { ENROLLMENT_TIME, ENROLL_MAX_ATTEMPS } = configData;

  // Redux
  const dispatch = useAppDispatch();
  const requestId = useAppSelector((state) => state.app.requestId);
  const deniedPermissions = useAppSelector(
    (state) => state.app.deniedPermissions,
  );
  const deviceFlag = useAppSelector((state) => state.app.deviceFlag);
  const mediaRecording = useAppSelector((state) => state.drawer.mediaRecording);
  const subDrawerOpen = useAppSelector((state) => state.drawer.isSubDrawerOpen);
  const livenessTipsAccepted = useAppSelector(
    (state) => state.app.livenessTipsAccepted,
  );
  const livenessError = useAppSelector((state) => state.app.livenessError);
  const shouldShowTipsImage = useAppSelector(
    (state) => state.app.shouldShowTipsImage,
  );
  const enrollErrorCounter = useAppSelector(
    (state) => state.app.enrollErrorCounter,
  );
  const isFirstInstruction = useAppSelector(
    (state) => state.app.isFirstInstruction,
  );
  const merchantConfiguration = useAppSelector(
    (state) => state.app.merchantConfiguration,
  );
  const sessionToken = useAppSelector((state) => state.app.sessionToken);

  // Refs
  const imageCaptureFinished = useRef(false);
  const isStartingStreamRef = useRef(false);
  const showInstructionsRef = useRef(showInstructions);
  const faceModel = useRef<FaceDetector | null>(null);
  const shouldRun = useRef(false);
  const livenessTipsDesktopDisplayed = useRef(livenessError);
  const videoRef = useRef<Webcam>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const intervalId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const audioIntervalId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const livenessPictureRef = useRef<LivenessPictureData>({
    blob: null,
    fileName: null,
  });

  // Media Devices
  const { state } = useContext(MediaContext);
  const { stream, videoTrack } = state;
  const { toggleOffCameraAndMic, toggleOnMic, toggleOnCamera } =
    useMediaDevices();

  const executeAsyncFn = async (fn: () => Promise<void>) => {
    fn && (await fn());
  };

  // WS Status
  const { connectionStatus, setConnectionStatus, handleWsMessage } =
    useWStatus();

  // custom hooks
  const { getRequestId } = useRequestId();

  // Function to detect faces in a video
  const detectFaces = useCallback(async (model: FaceDetector) => {
    if (!videoRef.current) {
      return;
    }

    const { video } = videoRef.current;
    let errors: Record<string, string>[] = [];

    if (video?.readyState !== 4) {
      return;
    }

    // Set the canvas and video dimensions
    const { videoWidth } = video;
    const { videoHeight } = video;

    if (canvasRef.current) {
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
      video.width = videoWidth;
      video.height = videoHeight;
    }

    // Get the predictions from the model
    const predictions = await model.estimateFaces(video);

    // Check if there are more than one face detected. Error 50%

    if (predictions.length > 1) {
      errors = [...errors, { faceQty: 'Too Many Faces' }];
      setImageProgressErrorText('Too Many Faces');
    } else if (predictions.length === 1) {
      // Check the distance between the face and the center of the video. Error 25%
      const values = predictions[0].box;

      const maxDistance = 0.9;
      const minDistance = 0.65;

      let imageProgressErrorText = '';

      if (calculateDistance(values.width) < minDistance) {
        errors = [...errors, { tooClose: 'Please: Move Away' }];
        imageProgressErrorText = 'Please: Move Away';
      } else if (calculateDistance(values.width) > maxDistance) {
        errors = [...errors, { minSize: 'Please: Get Closer' }];
        imageProgressErrorText = 'Please: Get Closer';
      } else if (isFaceCloseToBorder(values, videoWidth, videoHeight)) {
        errors = [...errors, { notCentered: 'Center Your Face' }];
        imageProgressErrorText = 'Center Your Face';
      }
      setImageProgressErrorText(imageProgressErrorText);
    } else if (!predictions.length) {
      setImageProgressErrorText('Is Anyone There?');
      return;
    }
    // Draw the predictions on the canvas
    if (!errors?.length) {
      faceNoErrors = true;
      if (predictions.length > 0) {
        setBlazefaceLoaded(true);
      }
    } else {
      faceNoErrors = false;
    }
  }, []);

  const detectErrorUserPosition = useCallback(async (model: FaceDetector) => {
    setUserPositionError(false);
    if (!videoRef.current) {
      return;
    }

    const { video } = videoRef.current;

    if (video?.readyState !== 4) {
      return;
    }

    const { videoWidth } = video;
    const { videoHeight } = video;

    if (canvasRef.current) {
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
      video.width = videoWidth;
      video.height = videoHeight;
    }

    const predictions = await model.estimateFaces(video);

    if (predictions.length > 1) {
      setUserPositionError(true);
    } else if (predictions.length === 1) {
      const values = predictions[0].box;

      const maxDistance = 0.9;
      const minDistance = 0.65;

      if (calculateDistance(values.width) < minDistance) {
        setUserPositionError(true);
      } else if (calculateDistance(values.width) > maxDistance) {
        setUserPositionError(true);
      } else if (isFaceCloseToBorder(values, videoWidth, videoHeight)) {
        setUserPositionError(true);
      }
    } else if (!predictions.length) {
      setUserPositionError(true);
    }
  }, []);

  const startFaceDetectionWhileAudio = useCallback(
    (model: FaceDetector) => {
      if (audioIntervalId.current !== null) {
        clearInterval(audioIntervalId.current);
      }
      const id = setInterval(() => {
        detectErrorUserPosition(model);
      }, 250);
      audioIntervalId.current = id;
    },
    [detectErrorUserPosition],
  );

  const stopFaceDetectionWhileAudio = useCallback(() => {
    if (audioIntervalId.current !== null) {
      clearInterval(audioIntervalId.current);
      audioIntervalId.current = null;
    }
  }, []);

  const handleLivenessTips = () => {
    if (enrollErrorCounter?.attempt === 0) {
      if (isMobile) {
        setShowInstructions?.(true);
      } else if (isFirstInstruction) {
        dispatch(setLivenessError(true));
        setSymbioCode('liveness-tips');
        dispatch(setIsFirstInstruction(false));
      }
    }
  };

  const clearDetectionTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    if (audioIntervalId.current) {
      clearInterval(audioIntervalId.current);
    }
  };

  async function speakBot(textToSpeek: string) {
    return new Promise((resolve) => {
      if (isMobile) setImageProgressErrorText('Analyzing...');
      speak(
        textToSpeek,
        () => {
          resolve(undefined);
        },
        localStorage,
      );
    });
  }

  // Function to start face detection
  const runTextToSpeech = async (textToSpeek: string) => {
    try {
      await speakBot(textToSpeek);
      shouldRun.current = true;
      if (intervalsPaused) {
        setIntervalsPaused(false);
        return;
      }
      if (intervalsPaused) {
        setIntervalsPaused(false);
        return;
      }
      intervals();
    } catch (err) {
      console.error(err);
    }
  };

  const intervals = () => {
    // Set an interval to run face detection
    intervalId.current = setInterval(() => {
      if (
        faceModel?.current &&
        (!showInstructionsRef.current ||
          (symbioCode === 'liveness-tips' && livenessError))
      ) {
        detectFaces(faceModel.current);
      }
    }, 250);

    // Set a timeout to stop the flow in case no face detection
    timeoutIdRef.current = setTimeout(() => {
      if (
        shouldRun?.current &&
        !showInstructionsRef.current &&
        !livenessTipsDesktopDisplayed.current
      ) {
        isAnError();
        shouldRun.current = false;
        setImageProgressErrorText('');
        if (intervalId.current) {
          clearInterval(intervalId.current);
          intervalId.current = null;
        }
      }
    }, 20000);
  };

  // Function to stop face detection
  const stopFacedetection = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    clearDetectionTimeout();
    setBlazefaceLoaded(false);
    shouldRun.current = false;
    setImageProgressErrorText('');
    canvasRef?.current && (canvasRef.current.style.display = 'none');
  }, []);

  // Function to snapshot on react-webcam. It will store the image
  const printImage = React.useCallback(() => {
    const imageSrc = videoRef.current?.getScreenshot();
    setImgSrc(imageSrc as string);
    if (imageSrc && imageSrc.length > 'data:image/jpeg;base64,'.length) {
      // Store the data URL in local storage
      localStorage?.setItem('profileImage', imageSrc);
    }
  }, [videoRef]);

  /**
   * Capture images from video and send to the server
   */

  const captureImage = useCallback(() => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas') as
        | HTMLCanvasElement
        | undefined;
      const video = document.getElementById('video-element') as
        | HTMLVideoElement
        | undefined;

      if (video && canvas) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext('2d')
          ?.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
          if (blob) {
            const { size, type } = blob;
            const dimensions = `${canvas.width} x ${canvas.height}`;
            const myBlobObject = { size, type, dimensions };
            console.debug('send image data ::', myBlobObject);
            ws?.send(blob);
            if (configData.SAVE_IMAGES) livenessPictureRef.current.blob = blob;
            resolve(undefined);
          }
        }, 'image/jpeg');
      }
    });
  }, [videoTrack, ws]);

  /**
   *    * Capture 5 images. By default, one per 2 seconds
   */
  const captureImagesSequentially = useCallback(async () => {
    const picturesToTake = 5;
    for (let i = 0; i < picturesToTake; i++) {
      await captureImage();
      const delay = (ENROLLMENT_TIME / picturesToTake) * 1000;
      await new Promise((resolve) => setTimeout(resolve, delay)); // Default: wait 2 seconds
    }
    printImage();
    imageCaptureFinished.current = true;
  }, [captureImage, printImage]);

  // A callback function that will be added as an event listener to the mediaRecorder
  const mediaRecorderListener = useCallback(
    (event: BlobEvent) => {
      if (isStreaming && ws && imageCaptureFinished?.current) {
        console.debug('send audio data ::', event.data);
        ws.send('audio');
        ws.send(event.data);
        ws.send('finished');
        audioRecorder?.removeEventListener(
          'dataavailable',
          mediaRecorderListener,
        );
        audioRecorder = null;
        dispatch(setProcessingMediaData(true));
        isStreaming = false;
      }
    },
    [isStreaming, ws],
  );

  const initAudioRecorder = useCallback(async () => {
    executeAsyncFn(toggleOnMic);
    let audioTrack: MediaStreamTrack | null = null;
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      audioTrack = newStream.getAudioTracks()[0];
    } catch (error) {
      console.error(
        'Microphone permission denied or other error occurred',
        error,
      );
    }
    if (audioTrack) {
      const newStream = new MediaStream();
      newStream.addTrack(audioTrack);
      let mimeSupported = [];
      mimeSupported = getSupportedAudioMimeTypes();

      const options = {
        audioBitsPerSecond: 128000,
        mimeType: mimeSupported[0],
      };
      audioRecorder = new MediaRecorder(newStream, options);
      audioRecorder.addEventListener('dataavailable', mediaRecorderListener);
    } else {
      console.error('No audio track available');
      toggleOnMic();
      isAnError();
    }
  }, []);

  const forceLivenessSuccess = useCallback(
    async (recognitionMessageId: string) => {
      faceModel.current && startFaceDetectionWhileAudio(faceModel.current);
      if (requestId && localStorage && sessionToken !== '') {
        if (!audioRecorder) await initAudioRecorder();
        setIsLivenessSuccessful(true); // Set circle bars to green.
        const { data } = await getRecognitionMessage(
          recognitionMessageId,
          requestId,
          localStorage,
          dispatch,
          sessionToken,
        );
        setIsLoading(false);
        setTextToRead(data.message);
        setProgress(100); // Start drawing the circle with bigger lines
        audioRecorder?.start();
        await captureImagesSequentially();
        setTextToRead(null);
        audioRecorder?.stop();
        stopFaceDetectionWhileAudio();
        setImageProgressText('Registering \n Your Face and Voice');
        setIsLoading(true);
      }
    },
    [captureImagesSequentially, initAudioRecorder, requestId, sessionToken],
  );

  const parseWebSocketFaceMessage = (message: string): ParsedMessage => {
    const match = message.match(/symbioResponseMessage: (.*)/);
    if (!match || !match[1]) {
      return { uuid: null, faceId: null };
    }

    const symbioResponse: SymbioResponse = JSON.parse(match[1]);
    if (symbioResponse.recognition_type !== 'face_result') {
      return { uuid: null, faceId: null };
    }

    const { recognition_event_uuid, result_data } = symbioResponse;
    const highestConfidenceData = result_data.reduce(
      (max, item) => {
        const data = Object.values(item)[0];
        return data.confidence > max.confidence ? data : max;
      },
      { id: null, confidence: 0 } as FaceIDData,
    );

    if (highestConfidenceData.confidence === 0) {
      return { uuid: recognition_event_uuid, faceId: 'NEW-USER' };
    }

    return {
      uuid: recognition_event_uuid,
      faceId: highestConfidenceData.id,
    };
  };

  const generateFileName = (uuid: string, faceId?: number | string): string => {
    const timeString = new Date().toISOString();
    return `UUID[${uuid}]_FACEID[${faceId}]_[${timeString}].jpeg`;
  };

  const handleSymbioCode = useCallback(
    async (response: { type: string; description: string }) => {
      // verifies the enrollment status
      switch (response.type) {
        case 'debug': {
          const { description } = response;
          const { uuid, faceId } = parseWebSocketFaceMessage(description);
          if (uuid && faceId && configData.SAVE_IMAGES) {
            const fileName = generateFileName(uuid, faceId);
            livenessPictureRef.current.fileName = fileName;
          }
          break;
        }
        case OnWorking:
          // reset the timeout every time a sync message is received
          handleWsMessage();
          break;
        case oAuthRequestIdCode:
          localStorage?.setItem('oAuthRequestId', response.description);
          break;
        case SuccessCode:
          setWsErrorMessage(undefined);
          await forceLivenessSuccess(response.description);
          break;
        case WsErrorMessageCode:
          setWsErrorMessage(response.description);
          break;
        case YellowCodeToken:
          localStorage?.setItem(`wssToken-${clientId}`, response.description);
          break;
        case YellowCode: {
          // Before the yellow flow, check that the profile is complete.
          if (requestId && localStorage && sessionToken && dispatch) {
            const { data } = await getProfileCompletion(
              requestId,
              localStorage,
              dispatch,
              sessionToken,
            );
            if (!data || data !== 100) {
              isAnError();
              dispatch(
                setEnrollErrorCounter({
                  attempt: enrollErrorCounter.attempt + 1,
                  status: 'resting',
                }),
              );
            } else {
              // yellow flow
              dispatch(setWinkToken(response.description));
              closeWebSocket();
              setIsLoading(false);
              dispatch(setMediaRecording(false));
              dispatch(setShowMediaDeviceIcons(false));
              dispatch(setIsSubDrawerOpen(false));
              setIsYellowCode(true);
              setSymbioCode(YellowCode);
            }
          } else {
            isAnError();
            dispatch(
              setEnrollErrorCounter({
                attempt: enrollErrorCounter.attempt + 1,
                status: 'resting',
              }),
            );
          }
          break;
        }
        case ErrorCode:
        case LivenessErrorCode:
          if (
            livenessPictureRef?.current?.blob &&
            livenessPictureRef?.current?.fileName
          ) {
            uploadImageToAzure(livenessPictureRef.current.blob, {
              ...uploadOptions,
              blobName: livenessPictureRef.current.fileName,
            })
              .then((response) => {
                console.debug(
                  'Liveness Filed Image saved in Azure -->',
                  response,
                );
              })
              .catch((error) => {
                console.error('Error saving liveness image in Azure:', error);
              });
          }
          isAnError();
          dispatch(
            setEnrollErrorCounter({
              attempt: enrollErrorCounter.attempt + 1,
              status: 'resting',
            }),
          );
          break;
        case FraudCode:
          setIsLoading(false);
          dispatch(setIsFraud(true));
          closeWebSocket();
          dispatch(setMediaRecording(false));
          break;
        case TokenCode:
          setIsLoading(false);
          setWsErrorMessage(undefined);
          localStorage?.setItem(`wssToken-${clientId}`, response.description);
          closeWebSocket();
          executeAsyncFn(toggleOffCameraAndMic);
          dispatch(setMediaRecording(false));
          if (onSuccess) onSuccess();
          break;
        case NewAccount:
          dispatch(setVoice(response.description));
          break;
        case ExistingAccountCode:
          dispatch(setVoice(response.description));
          setIsLoading(false);
          executeAsyncFn(toggleOffCameraAndMic);
          dispatch(setMediaRecording(false));
          setSymbioCode(ExistingAccountCode);
          break;
        case IncompleteEnrollment:
          setIsLoading(false);
          setSymbioCode(IncompleteEnrollment);
          break;
        case VoiceLivenessError:
          setIsLoading(false);
          setSymbioCode(VoiceLivenessError);
          closeWebSocket();
          executeAsyncFn(toggleOffCameraAndMic);
          dispatch(setMediaRecording(false));
          break;
        default:
          break;
      }
    },
    [
      clientId,
      dispatch,
      onSuccess,
      setSymbioCode,
      toggleOffCameraAndMic,
      forceLivenessSuccess,
      enrollErrorCounter,
    ],
  );

  /**
   * Handle liveness and symbio responses
   */
  const handleWebSocketResponse = useCallback(
    async ({ data }: MessageEvent<any>) => {
      const response = JSON.parse(data);
      handleSymbioCode(response);
    },
    [handleSymbioCode],
  );

  const connectWebSocket = useCallback(() => {
    let websocketUrl = `wss://${configData.WS_BACKEND_URL}/ws_winkLoginEnrollment/${requestId}/true/true/${clientId}/?SessionId=${sessionToken}`;

    if (localStorage) {
      const wssToken = getToken(localStorage);
      if (wssToken) {
        websocketUrl = `wss://${configData.WS_BACKEND_URL}/ws_winkLoginEnrollment/${requestId}/true/true/${clientId}/${wssToken}/?SessionId=${sessionToken}`;
      }
    }

    if (invitationId) {
      websocketUrl += `&InvitationId=${invitationId}`;
    }

    ws = new WebSocket(websocketUrl);
    ws.onopen = () => {
      console.debug(`Connected to: ${websocketUrl}`);
      setConnectionStatus('connected');
    };
    ws.onmessage = handleWebSocketResponse;
    ws.onclose = () => {
      console.debug('WS closed');
      setConnectionStatus('closed');
    };
  }, [
    clientId,
    requestId,
    handleWebSocketResponse,
    localStorage,
    sessionToken,
  ]);

  const waitForWebSocketConnection = useCallback(
    (callback: () => Promise<void>) => {
      setTimeout(() => {
        if (!ws) {
          connectWebSocket();
          waitForWebSocketConnection(callback);
        } else if (ws?.readyState === WebSocket.OPEN) {
          console.debug('new websocket connection established');
          callback();
        } else {
          waitForWebSocketConnection(callback);
        }
      }, 1000);
    },
    [connectWebSocket],
  );

  const checkFaceHasNoErrors = (callback: () => Promise<void>) => {
    if (faceNoErrors) {
      setTimeout(() => {
        if (faceNoErrors) {
          callback();
        } else {
          checkFaceHasNoErrors(callback);
        }
      }, 1000);
    } else {
      setTimeout(() => {
        checkFaceHasNoErrors(callback);
      }, 500);
    }
  };

  const handleCaptureMedia = useCallback(async () => {
    if (!ws || ws.readyState !== 1) {
      // "1" means connection OPEN, so we need to ensure that the websocket connection is established
      waitForWebSocketConnection(handleCaptureMedia);
      return;
    }
    console.debug('Checking liveness');
    setIsLoading(true);
    isStreaming = true;
    ws?.send('images');
    await captureImage();
    setHasCaptured(true);
    stopFacedetection();
  }, [captureImage, stopFacedetection, waitForWebSocketConnection]);

  const closeWebSocket = () => {
    if (ws) {
      ws?.close();
      ws = null;
    }
  };

  const isAnError = () => {
    setIsLoading(false);
    closeWebSocket();
    dispatch(setMediaRecording(false));
    setUserImageError(true);
    setHasCaptured(false);
  };

  const loadFaceDetector = async () => {
    try {
      if (!faceModel?.current) {
        const { MediaPipeFaceDetector } = faceDetection.SupportedModels;
        const detectorConfig: MediaPipeFaceDetectorTfjsModelConfig = {
          runtime: 'tfjs',
          maxFaces: 2,
          detectorModelUrl: '/models/model.json',
        };
        faceModel.current = await faceDetection.createDetector(
          MediaPipeFaceDetector,
          detectorConfig,
        );
      }
    } catch (error) {
      console.error('Error loading face detector:', error);
      isAnError();
    }
  };

  const restart = () => {
    if (!ws) connectWebSocket();
    setImgSrc(null);
    setUserImageError(false);
    setUserImageSuccess(false);
    dispatch(
      setEnrollErrorCounter({
        ...enrollErrorCounter,
        status: 'analyzing',
      }),
    );
    dispatch(setMediaRecording(true));
    imageCaptureFinished.current = false;
    setImageProgressText('');
    setImageProgressErrorText('');
    setProgress(0);
    setConnectionStatus('');
    // Init bot synthetizer because IOS need the user interaction to speak
    restartSynthesizer();
    initSynthesizer();
    setIsLivenessSuccessful(false);
    setHasCaptured(false);
    dispatch(setProcessingMediaData(false));
    runTextToSpeech('');
    setShowInstructions?.(false);
    dispatch(setLivenessTipsAccepted(true));
    dispatch(setLivenessError(false));
    executeAsyncFn(toggleOnCamera);
  };

  const isRestingWithAttempts =
    enrollErrorCounter?.status === 'resting' && enrollErrorCounter?.attempt > 0;
  const firstRetry = enrollErrorCounter?.attempt === 1;

  const handleRetryAction = useCallback(() => {
    setWsErrorMessage(undefined);
    if (firstRetry) {
      dispatch(setLivenessError(true));
      setLivenessTipsActive(true);
      if (!isMobile) {
        setSymbioCode('liveness-tips');
      } else {
        setShowInstructions?.(true);
      }
    } else {
      setSymbioCode(null);
      restart();
    }
  }, [firstRetry, shouldShowTipsImage, restart]);

  useEffect(() => {
    if (intervalsPaused) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    } else if (intervalsPaused === false && shouldRun?.current) {
      intervals();
    }
  }, [intervalsPaused]);

  useEffect(() => {
    if (
      (symbioCode === 'liveness-tips' || showInstructions) &&
      enrollErrorCounter?.attempt > 0
    ) {
      setIntervalsPaused(true);
    } else {
      setIntervalsPaused(false);
    }
  }, [symbioCode, showInstructions]);

  useEffect(() => {
    showInstructionsRef.current = showInstructions;
  }, [showInstructions]);

  useEffect(() => {
    livenessTipsDesktopDisplayed.current = livenessError;
  }, [livenessError]);

  useEffect(() => {
    if (livenessTipsAccepted && enrollErrorCounter?.attempt > 0) {
      restart();
    }
    dispatch(setLivenessTipsAccepted(false));
  }, [livenessTipsAccepted]);

  useEffect(() => {
    if (yellowRetryPressed) {
      setWsErrorMessage(undefined);
      setIsYellowCode(false);
      setYellowRetryPressed(false);
      dispatch(setIsSubDrawerOpen(true));
      setSymbioCode(null);
      restart();
    }
  }, [yellowRetryPressed]);

  useEffect((): (() => void) | undefined => {
    const permissionsGranted = getGrantedPermissions(localStorage);
    if (!permissionsGranted || !JSON.parse(permissionsGranted)) {
      dispatch(setDeniedPermissions(true));
      return undefined;
    } else if (!isStartingStreamRef?.current) {
      isStartingStreamRef.current = true;
      const verifyDeviceAndGetRequestId = async () => {
        try {
          dispatch(setAppIsLoading(true));
          const fetchRequestIdIfNull =
            requestId || ((await getRequestId()) as string);
          if (localStorage && !requestId) {
            const { data } = await verifyDevice({
              clientId,
              requestId: fetchRequestIdIfNull,
              localStorage,
              dispatch,
              sessionToken,
            });
            dispatch(setDeviceFlag(data?.deviceFlag));
            dispatch(setSessionToken(data?.jwtSessionToken));
          }
        } catch (err) {
          dispatch(setIsFraud(true));
          console.error(err);
        } finally {
          dispatch(setAppIsLoading(false));
        }
      };
      verifyDeviceAndGetRequestId();
      setImageProgressErrorText('Preparing to capture');
      dispatch(
        setEnrollErrorCounter({
          ...enrollErrorCounter,
          status: 'analyzing',
        }),
      );
      // stream to display video on UI
      if (videoTrack && stream) {
        if (videoRef.current?.video) {
          videoRef.current.video.srcObject = stream;
        }
      } else {
        executeAsyncFn(toggleOnCamera);
      }
      dispatch(setMediaRecording(true));
      dispatch(setShowMediaDeviceIcons(true));
      const cleanup = () => {
        if (intervalId.current) {
          stopFacedetection();
        }
        audioRecorder = null;
      };

      return () => {
        cleanup();
      };
    }
    return undefined;
  }, [deniedPermissions]);

  useEffect(() => {
    requestId && clientId && sessionToken && connectWebSocket();
  }, [requestId, clientId, sessionToken]);

  useEffect(() => {
    if (ws?.readyState === 1) {
      isStartingStreamRef.current = false;
    }
  }, [ws]);

  useEffect(() => {
    // TODO useRef instead of let
    // eslint-disable-next-line prefer-const
    let interval: ReturnType<typeof setInterval>;
    const reactWebcamVideo = videoRef?.current?.video;
    const checkVideoReadyState = () => {
      if (reactWebcamVideo?.readyState === 4) {
        setIsVideoReady(true);
        clearInterval(interval);
      }
    };

    interval = setInterval(checkVideoReadyState, 300);

    return () => {
      clearInterval(interval);
    };
  }, [videoTrack, videoRef]);

  useEffect(() => {
    if (
      videoTrack &&
      isVideoReady &&
      deviceFlag !== null &&
      isBackendWasm &&
      symbioCode === null &&
      !livenessError
    ) {
      runTextToSpeech(instructions.enrollment.title);
    }
  }, [
    videoTrack,
    isVideoReady,
    deviceFlag,
    isBackendWasm,
    symbioCode,
    livenessError,
  ]);

  useEffect(() => {
    // If the red bars stays colored in red for 45 secs, we send the user to the 'Unsuccesfull Enrollment' screen
    let timeoutId: ReturnType<typeof setTimeout>;
    if (userImageError && !isYellowCode) {
      timeoutId = setTimeout(() => {
        setSymbioCode(ErrorCode);
        executeAsyncFn(toggleOffCameraAndMic);
        setShowInstructions?.(false);
        dispatch(setLivenessError(false));
      }, 45000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [userImageError]);

  useEffect(() => {
    if (enrollErrorCounter.attempt === ENROLL_MAX_ATTEMPS) {
      setSymbioCode(EnrollMaxErrors);
      executeAsyncFn(toggleOffCameraAndMic);
    }
    if (enrollErrorCounter?.attempt > 1) {
      dispatch(setShouldShowTipsImage(true));
    }
  }, [enrollErrorCounter]);

  useEffect(() => {
    if (
      blazefaceLoaded &&
      !hasCaptured &&
      faceNoErrors &&
      !deniedPermissions &&
      symbioCode !== 'liveness-tips' &&
      connectionStatus === 'connected'
    ) {
      checkFaceHasNoErrors(handleCaptureMedia);
    }
  }, [blazefaceLoaded, hasCaptured, connectionStatus]);

  useEffect(() => {
    if (
      connectionStatus === 'error' ||
      (connectionStatus === 'closed' && mediaRecording)
    ) {
      isAnError();
    }
  }, [connectionStatus]);

  useEffect(() => {
    loadFaceDetector();
    handleLivenessTips();
    return () => {
      if (stream) {
        // avoid zoom on iOS if this component is remounted
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
      clearDetectionTimeout();
    };
  }, []);

  return (
    <div style={styles.container}>
      {isLoading && <Loader />}
      {!isYellowCode && (
        <>
          <div
            style={{
              position: 'relative',
              height:
                symbioCode === 'liveness-tips' &&
                enrollErrorCounter?.attempt === 0
                  ? 0
                  : 'auto',
              visibility:
                subDrawerOpen &&
                (symbioCode !== 'liveness-tips' ||
                  (symbioCode === 'liveness-tips' &&
                    enrollErrorCounter?.attempt > 0))
                  ? 'visible'
                  : 'hidden', // Re-mounting this component leads to bugs in iPhone and takes different resolution. Instead, we hide it when we capture the last image.
            }}
          >
            <audio ref={audioRef} />
            <ImageProgress
              success={isLivenessSuccessful}
              progress={progress}
              text={
                userImageSuccess
                  ? 'Face and voice registered'
                  : imageProgressText
              }
              textError={imageProgressErrorText}
              defaultSuccess={userImageSuccess}
              defaultError={userImageError}
              textToRead={textToRead}
              userPositionError={userPositionError}
            >
              {imgSrc && (
                <img alt="user face" src={imgSrc} style={styles.img} />
              )}

              <>
                <Webcam
                  ref={videoRef}
                  videoConstraints={{
                    width: { ideal: 1280, max: 1280, min: 720 },
                    height: {
                      ideal: 720,
                      max: 720,
                      min: 540,
                    },
                  }}
                  id="video-element"
                  playsInline
                  autoPlay
                  muted
                  style={{
                    ...styles.video,
                    transform: 'scaleX(-1)',
                    filter: isLoading ? 'blur(4px)' : undefined,
                    visibility:
                      subDrawerOpen &&
                      !imgSrc &&
                      (symbioCode !== 'liveness-tips' ||
                        (symbioCode === 'liveness-tips' &&
                          enrollErrorCounter?.attempt > 0))
                        ? 'visible'
                        : 'hidden', // Re-mounting this component leads to bugs in iPhone and takes different resolution. Instead, we hide it when we capture the last image.
                  }}
                />
                <canvas ref={canvasRef} style={styles.canvas} />
              </>
            </ImageProgress>
            {(isRestingWithAttempts || userImageError) && (
              <>
                {shouldShowTipsImage && (
                  <LivenessTipImage
                    src="assets/liveness-tips.svg"
                    alt="liveness tips"
                    onClick={(e: MouseEvent<HTMLImageElement>) => {
                      e?.stopPropagation();
                      setLivenessTipsActive(true);
                      dispatch(setLivenessError(!livenessError));
                      if (!isMobile) {
                        setSymbioCode(livenessError ? null : 'liveness-tips');
                      } else {
                        dispatch(setLivenessError(true));
                        setShowInstructions?.(true);
                      }
                    }}
                  />
                )}
                {!livenessTipsActive ? (
                  <StyledButton
                    onClick={(e) => {
                      e?.stopPropagation();
                      handleRetryAction();
                    }}
                    sx={{ width: '85%', mt: 1.5, mx: 'auto', display: 'flex' }}
                  >
                    Retry
                  </StyledButton>
                ) : (
                  <StyledButton
                    onClick={(e) => {
                      e?.stopPropagation();
                      setWsErrorMessage(undefined);
                      setSymbioCode(null);
                      dispatch(setLivenessError(false));
                      setLivenessTipsActive(false);
                      dispatch(setShouldShowTipsImage(false));
                      restart();
                    }}
                    sx={{ width: '85%', mt: 1.5, mx: 'auto', display: 'flex' }}
                  >
                    Try again
                  </StyledButton>
                )}
              </>
            )}
          </div>
          <div
            style={{
              ...styles.livenessTipWrapper,
              display:
                symbioCode === 'liveness-tips' &&
                enrollErrorCounter?.attempt === 0
                  ? 'flex'
                  : 'none',
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Grid item xs={12} textAlign="center" paddingTop={0}>
              <Typography
                fontWeight="600"
                fontSize="1rem"
                component="div"
                fontFamily={merchantConfiguration.font}
              >
                Get Ready for Your Video Verification
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              textAlign="center"
              marginBottom={2}
              paddingTop={0}
            >
              <img
                src="../../../assets/wink-phototips-good.png"
                width="180"
                height="auto"
                id="subdrawerLivenessTipImage"
              />
              <Typography
                variant="h3"
                fontSize="14px"
                textAlign="center"
                lineHeight="20px"
                component="div"
                fontFamily={merchantConfiguration.font}
              >
                Get securely verified with a{' '}
                <Typography
                  variant="h2"
                  fontSize="16px"
                  color="#1AA7B0"
                  textAlign="center"
                  fontWeight={700}
                  display="inline-block"
                  lineHeight="20px"
                >
                  well-lit
                </Typography>
                <br />
                smile,{' '}
                <Typography
                  variant="h2"
                  fontSize="16px"
                  color="#5762A3"
                  textAlign="center"
                  fontWeight={700}
                  display="inline-block"
                  lineHeight="20px"
                >
                  facing forward
                </Typography>{' '}
                and <br />
                <Typography
                  variant="h2"
                  fontSize="16px"
                  color="#1AA7B0"
                  textAlign="center"
                  fontWeight={700}
                  display="inline-block"
                  lineHeight="20px"
                >
                  centered
                </Typography>{' '}
                with your{' '}
                <Typography
                  variant="h2"
                  fontSize="16px"
                  color="#5762A3"
                  textAlign="center"
                  fontWeight={700}
                  display="inline-block"
                  lineHeight="20px"
                >
                  ears visible.
                </Typography>
              </Typography>
            </Grid>
            <StyledButton
              onClick={(e) => {
                e?.stopPropagation();
                setSymbioCode(null);
                dispatch(setLivenessError(false));
                // on attempt 0 Liveness Tips are auto displayed and we dont want a restart
                enrollErrorCounter?.attempt > 0 && restart();
              }}
              sx={{ fontFamily: merchantConfiguration?.font }}
              id="LetsDoThisButton"
            >
              Let’s Do This
            </StyledButton>
          </div>
        </>
      )}
    </div>
  );
};
